/* @link https://utopia.fyi/space/calculator?c=320,16,1.067,1272,16,1.125,10,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
  --fc-4xs-min: (var(--fc-s-min) * 0.125); 
  --fc-4xs-max: (var(--fc-s-max) * 0.125);

  --fc-3xs-min: (var(--fc-s-min) * 0.25); 
  --fc-3xs-max: (var(--fc-s-max) * 0.25);

  --fc-2xs-min: (var(--fc-s-min) * 0.5); 
  --fc-2xs-max: (var(--fc-s-max) * 0.5);

  --fc-xs-min: (var(--fc-s-min) * 0.75); 
  --fc-xs-max: (var(--fc-s-max) * 0.75);

  --fc-s-min: (var(--f-2-min)); 
  --fc-s-max: (var(--f-2-max));

  --fc-m-min: (var(--fc-s-min) * 1.5); 
  --fc-m-max: (var(--fc-s-max) * 1.5);

  --fc-l-min: (var(--fc-s-min) * 2); 
  --fc-l-max: (var(--fc-s-max) * 2);

  --fc-xl-min: (var(--fc-s-min) * 3); 
  --fc-xl-max: (var(--fc-s-max) * 3);

  --fc-2xl-min: (var(--fc-s-min) * 4); 
  --fc-2xl-max: (var(--fc-s-max) * 4);

  --fc-3xl-min: (var(--fc-s-min) * 6); 
  --fc-3xl-max: (var(--fc-s-max) * 6);

  /* T-shirt sizes */
  --space-4xs: calc(((var(--fc-4xs-min) / 16) * 1rem) + (var(--fc-4xs-max) - var(--fc-4xs-min)) * var(--fluid-bp));
  --space-3xs: calc(((var(--fc-3xs-min) / 16) * 1rem) + (var(--fc-3xs-max) - var(--fc-3xs-min)) * var(--fluid-bp));
  --space-2xs: calc(((var(--fc-2xs-min) / 16) * 1rem) + (var(--fc-2xs-max) - var(--fc-2xs-min)) * var(--fluid-bp));
  --space-xs: calc(((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-xs-max) - var(--fc-xs-min)) * var(--fluid-bp));
  --space-s: calc(((var(--fc-s-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-s-min)) * var(--fluid-bp));
  --space-m: calc(((var(--fc-m-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-m-min)) * var(--fluid-bp));
  --space-l: calc(((var(--fc-l-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-l-min)) * var(--fluid-bp));
  --space-xl: calc(((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-xl-min)) * var(--fluid-bp));
  --space-2xl: calc(((var(--fc-2xl-min) / 16) * 1rem) + (var(--fc-2xl-max) - var(--fc-2xl-min)) * var(--fluid-bp));
  --space-3xl: calc(((var(--fc-3xl-min) / 16) * 1rem) + (var(--fc-3xl-max) - var(--fc-3xl-min)) * var(--fluid-bp));
  
  /* One-up pairs */
  --space-3xs-2xs: calc(((var(--fc-3xs-min) / 16) * 1rem) + (var(--fc-2xs-max) - var(--fc-3xs-min)) * var(--fluid-bp));
  --space-2xs-xs: calc(((var(--fc-2xs-min) / 16) * 1rem) + (var(--fc-xs-max) - var(--fc-2xs-min)) * var(--fluid-bp));
  --space-xs-s: calc(((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-xs-min)) * var(--fluid-bp));
  --space-s-m: calc(((var(--fc-s-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-s-min)) * var(--fluid-bp));
  --space-m-l: calc(((var(--fc-m-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-m-min)) * var(--fluid-bp));
  --space-l-xl: calc(((var(--fc-l-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-l-min)) * var(--fluid-bp));
  --space-xl-2xl: calc(((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-2xl-max) - var(--fc-xl-min)) * var(--fluid-bp));
  --space-2xl-3xl: calc(((var(--fc-2xl-min) / 16) * 1rem) + (var(--fc-3xl-max) - var(--fc-2xl-min)) * var(--fluid-bp));

  /* Custom pairs */
  --space-s-l: calc(((var(--fc-s-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-s-min)) * var(--fluid-bp));
  --space-l-2xl: calc(((var(--fc-l-min) / 16) * 1rem) + (var(--fc-2xl-max) - var(--fc-l-min)) * var(--fluid-bp));

  --gutter: var(--space-s-l);
  --negative-gutter: calc(-1 * var(--gutter));
  --border-radius: 11px;

  --bleed-space: max(var(--gutter), calc((100vw - var(--wrapper)) / 2));
}